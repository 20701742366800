import { Auction, Item } from '@/api';
import BidTimer from '@/components/general/BidTimer';
import Price from '@/components/general/Price';
import useAuth from '@/contexts/AuthContext';
import { setUpCalendarReminder } from '@/helpers/Calendar';
import i18next from 'i18n';
import Link from 'next/link';

const ProductTileBidSection =
    ({ auction, hasAuction, comingSoon, auctionOver, item }: {
        auction: Omit<Auction, 'item'> | undefined;
        hasAuction: boolean;
        comingSoon: { startTime: Date; } | undefined;
        auctionOver: boolean;
        item: Item
    }) => {
        const { user } = useAuth();

        if (auction && comingSoon) {
            return (
                <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                    <div className="flex flex-col">
                        <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('common:product-tile.starts-soon')}</span>
                        <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                            <span className="text-[16px] font-[500] cursor-pointer text-black hover:underline mt-[-4px]" onClick={() => setUpCalendarReminder(auction, item, user)}>{i18next.t('common:button-text.remind-me')}</span>
                            <span className="mt-[-4px]">{comingSoon && comingSoon?.startTime && <BidTimer dueDate={comingSoon?.startTime} />}</span>
                        </div>
                    </div>
                </Link>
            );
        }
        if (item.check_offers_possible && item.last_sold_amount && item.last_sold_amount > '0.00') {
            return (
                <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                    <div className="flex flex-col">
                        <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('common:product-tile.last-sold')}</span>
                        <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                            <Price className="text-[16px] tracking-[-0.2px] font-[500] font-secondary " justification="L" number={item.last_sold_amount ?? '0'} />
                        </div>
                    </div>
                </Link>
            );
        }

        switch (auction?.type) {
            case Auction.TypeEnum.Auction:
                if (hasAuction && !auctionOver && !comingSoon) {
                    return (
                        <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                            <div className="flex flex-col">
                                <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('item-text.current-bid-text.current-bid')}</span>
                                <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                                    <Price className="text-[16px] tracking-[-0.2px] font-[500] font-secondary " justification="L" number={auction?.current_amount ?? '0'} />
                                </div>
                            </div>
                        </Link>
                    );
                }
                if (auction && hasAuction && auctionOver && !comingSoon) {
                    return (
                        <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                            <div className="flex flex-col">
                                <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('common:product-tile.winning-bid')}</span>
                                <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                                    <Price className="text-[16px] tracking-[-0.2px] font-[500] font-secondary " justification="L" number={auction?.current_amount ?? '0'} />
                                    {auction.amount_of_bids} {i18next.t('common:product-tile.bids')}
                                </div>
                            </div>
                        </Link>
                    );
                }
                return null;
            case Auction.TypeEnum.BuyNow:
                if (auctionOver) {
                    return (
                        <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                            <div className="flex flex-col">
                                <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('common:product-tile.sold-out')}</span>
                                <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                                    <Price className="text-[16px] tracking-[-0.2px] font-[500] font-secondary " justification="L" number={auction?.buy_now_amount ?? '0'} />
                                </div>
                            </div>
                        </Link>
                    );
                }
                return (
                    <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}>
                        <div className="flex flex-col">
                            <span className="text-grey15 text-[12px] font-[500] font-secondary tracking-[-0.024px]">{i18next.t('common:product-tile.buy-now')}</span>
                            <div className="flex flex-row items-center justify-between text-[16px] font-[500] tracking-[-0.2px]">
                                <Price className="text-[16px] tracking-[-0.2px] font-[500] font-secondary " justification="L" number={auction?.buy_now_amount ?? '0'} />
                            </div>
                        </div>
                    </Link>
                );
            default:
                return null;
        }
    };

export default ProductTileBidSection;
