import { Auction, Item } from '@/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SandWatchIcon from 'public/assets/icons/sand-watch.svg';
import ShoppingBagIcon from 'public/assets/icons/shopping-bag.svg';
import CalendarIcon from 'public/assets/icons/calendar.svg';

import CopyIcon from 'public/assets/icons/copy.svg';
import EmailIcon from 'public/assets/icons/social-share/email.svg';
import FacebookIcon from 'public/assets/icons/social-share/facebook.svg';
import RedditIcon from 'public/assets/icons/social-share/reddit.svg';
import TwitterIcon from 'public/assets/icons/social-share/twitter.svg';

import {
    EmailShareButton,
    TwitterShareButton,
    FacebookShareButton,
    RedditShareButton,
} from 'react-share';
import toast from 'react-hot-toast';
import useAuction from '@/hooks/useAuction';
import i18next from 'i18n';
import { useMemo } from 'react';
import { getPoster } from '@/app/utils/item-utils';
import clsx from 'clsx';
import useAuth from '@/contexts/AuthContext';
import ProductBreadCrumbs from './product/ProductBreadCrumbs';
import AuthCode from './AuthCode';
import ProductBadge from './product/ProductBadge';
import Price from '../general/Price';
import BidTimer from '../general/BidTimer';
import DateFormat from '../general/DateFormat';

const Share = ({ product, isLoading, isMobile }: { product: Item, isLoading: boolean, isMobile?: boolean }) => {
    const itemUrl = product ? `https://${window.location.host}/item/${product.slug}` : '';
    const { hasAuction, auctionType, auction } = useAuction(product);
    const firstImage = useMemo(() => getPoster(product.images), [product]);
    const { user } = useAuth();

    if (isLoading || !product) {
        return null;
    }

    const { private: isPrivate, tr_id: trId, trending } = product;

    let { name } = product;

    if (isPrivate && !user) {
        name = i18next.t('item-text.private-title');
    }

    return (
        <div className={clsx('flex flex-col-reverse md:flex-row items-center pb-[24px] md:pb-[0] justify-between w-full', !isMobile ? 'overflow-y-scroll md:overflow-y-auto' : '')}>
            <div className="flex-col flex justify-center md:max-w-[420px] w-[100vw] max-w-[100%] px-[24px] md:px-[0]">
                <ProductBreadCrumbs className="hidden md:flex" item={product} />
                <h1 className="text-[30px] leading-[30px] font-heading capitalize mb-4 text-center md:text-left md:text-[40px] md:leading-[40px] mt-[24px] md:mt-[0]">
                    {name}
                </h1>
                <div className=" flex-row space-x-3 mb-10 md:justify-start justify-center hidden md:flex">
                    <AuthCode type={product.authentication_type} id="share">
                        {trId}
                    </AuthCode>
                    {trending && <ProductBadge theme="purple" type="trending" />}
                </div>
                {hasAuction && auctionType === Auction.TypeEnum.Auction &&
                    <div className="md:max-w-[400px]">
                        <div className="">
                            <span className="text-sm">{i18next.t('item-text.current-bid-text.current-bid')}:</span>
                            <span><Price className="text-[1.375rem] font-[700]" justification="L" number={auction?.current_amount ?? '0'} /></span>
                        </div>
                        <div
                            className="flex flex-row justify-between items-center text-md text-blue9 font-[500] border-b-[1px] border-t-[1px] py-2">
                            <span className="flex items-center mr-3">
                                <ShoppingBagIcon />
                                <span className="ml-1">
                                    {auction?.amount_of_bids}
                                </span>
                            </span>
                            <span className="flex items-center">
                                <SandWatchIcon />
                                <span className="ml-1"><BidTimer dueDate={new Date(auction?.end_date ?? '')} /></span>
                            </span>
                            <span className="flex items-center">
                                <CalendarIcon />
                                <span className="ml-1"><DateFormat date={auction?.end_date ?? ''} customMask="mm/dd/yy h:MM" /></span>
                            </span>
                        </div>
                    </div>
                }

                <div className="space-x-[12px] mt-[12px]">
                    <FacebookShareButton
                        url={itemUrl ?? ''}
                        quote={isPrivate ? i18next.t('item-text.private-title') : name}
                    >
                        <div className="border-[1px] border-grey9 bg-grey12 w-[44px] h-[44px] rounded-full flex justify-center items-center">
                            <FacebookIcon />
                        </div>
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={itemUrl ?? ''}
                        title={isPrivate ? i18next.t('item-text.private-title') : name}
                    >
                        <div className="border-[1px] border-grey9 bg-grey12 w-[44px] h-[44px] rounded-full flex justify-center items-center">
                            <TwitterIcon />
                        </div>
                    </TwitterShareButton>

                    <RedditShareButton
                        url={itemUrl ?? ''}
                        title={isPrivate ? i18next.t('item-text.private-title') : name}
                        windowWidth={660}
                        windowHeight={460}
                    >
                        <div className="border-[1px] border-grey9 bg-grey12 w-[44px] h-[44px] rounded-full flex justify-center items-center">
                            <RedditIcon size={32} round />
                        </div>
                    </RedditShareButton>

                    <EmailShareButton
                        url={itemUrl ?? ''}
                        subject={isPrivate ? i18next.t('item-text.private-title') : name}
                    >
                        <div className="border-[1px] border-grey9 bg-grey12 w-[44px] h-[44px] rounded-full flex justify-center items-center">
                            <EmailIcon />
                        </div>
                    </EmailShareButton>
                </div>

                <div className="mt-[12px]">
                    <span className="text-[16px] font-[500] inline-block mb-[8px]">{i18next.t('item-text.share-copy')}:</span>
                    <CopyToClipboard
                        text={itemUrl}
                        onCopy={() => toast.success(i18next.t('success-notification.copied-to-clipboard'))}>
                        <div className="p-[12px] cursor-pointer rounded-[8px] whitespace-nowrap overflow-hidden text-ellipsis over border-[1px] border-grey9 bg-grey12 text-[16px] text-main">

                            <button><CopyIcon className="inline-block w-[18px] mr-[12px] cursor-pointer" /></button>

                            {itemUrl}
                        </div>
                    </CopyToClipboard>

                </div>

            </div>

            <div className="w-full md:max-w-[297px] max-w-[100vw] px-[24px] md:px-[0]">
                <img src={firstImage?.url} alt="" />
            </div>
        </div>
    );
};

export default Share;
