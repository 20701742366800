import { Item } from '@/api';
import clsx from 'clsx';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Link from 'next/link';

const AuthCode = ({ children, isLoading, type, id, noToolTip = false }: { children: string, isLoading?: boolean, type?: Item.AuthenticationTypeEnum, id: string, noToolTip?: boolean }) => {
    if (isLoading) {
        return <Skeleton width={110} height={30} borderRadius={15} />;
    }

    if (!type) {
        return null;
    }

    const getIcon = () => {
        switch (type) {
            case Item.AuthenticationTypeEnum.Silver:
                return '/assets/icons/auth-code-icon-silver.svg';
            case Item.AuthenticationTypeEnum.Gold:
                return '/assets/icons/auth-code-icon-gold.svg';

            default:
                break;
        }
    };

    return (
        <>
            <div
                className={clsx('flex flex-row  items-center  w-fit font-[500] rounded-full text-[12px] py-[5px] px-2', {
                    'border-[1px] border-[#D9ECFF]': type === Item.AuthenticationTypeEnum.Silver,
                    'border-[1px] border-[#E3BA2C]': type === Item.AuthenticationTypeEnum.Gold
                })}
                data-tooltip-id={`tooltip-auth-code-${id}`}>
                <img
                    src={getIcon()}
                    className="w-[16px] h-[16px]"
                    alt="tr-id"
                />
                <div className="mx-2 leading-[21px]">{children}</div>
            </div>
            {!noToolTip &&
                <ReactTooltip
                    id={`tooltip-auth-code-${id}`}
                    place="top"
                    clickable
                    className="max-w-[80%]"
                >
                    <span>{type === Item.AuthenticationTypeEnum.Gold ? i18next.t('auth-code.gold') : i18next.t('auth-code.silver')}</span>
                    {' '}<Link href="/authentication" className="underline" target="_blank">{i18next.t('button-text.learn-more')}</Link>
                </ReactTooltip>
            }
        </>
    );
};

export default AuthCode;
